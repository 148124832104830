document.body.onload = function () {
    var preloader = document.querySelector('#preloader');
    
    setTimeout( function(){
        if( !preloader.classList.contains('done') ){
            preloader.classList.add('done');
        }
    },1000)
}



$(document).ready(function () {
    $('.header__call-back').click(function (event) {
        event.preventDefault();
        $('.overlay').addClass('overlay_visible');
        $('.modal_call-back').addClass('modal_visible ');
    });
    $('.overlay, .modal__close-button').click(function (event) {
        event.preventDefault();
        $('.overlay').removeClass('overlay_visible');
        $('.modal_call-back').removeClass('modal_visible');
        $('.modal_free').removeClass('modal_visible');
    });
    $(".hamburger").click(function () {
        $(".hamburger").toggleClass("is-active");
        $(".main-menu").toggleClass("main-menu_active");
    });

    $('.main-slider').slick({
        dots: true,
        prevArrow: '<button type="button" class="slider-button slider-button_prev"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>',
        nextArrow: '<button type="button" class="slider-button slider-button_next"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>',
        dotsClass: 'slider-dots',
        fade: true,
    });

    $('a[href^="#"]').click(function () {
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(target).offset().top
        }, 800);
        return false;
    });

    $("[data-fancybox]").fancybox({

	});

    $(document).on('submit', '#call-back-form', submit);
    $(document).on('submit', '#sub-form', submit);
    $(document).on('submit', '#sub-form-detail', submit);
    $(document).on('submit', '#free-training', submit);

    $('.header__free').on('click', function(e){
        e.preventDefault();
        $('.overlay').addClass('overlay_visible');
        $('.modal_free').addClass('modal_visible');
    });

});

function submit(){
    if( $(this).attr('id') == 'call-back-form') {
        $(this).addClass('modal__form_hidden');
        $('.windows8').addClass('windows8_visible');
    }

    if( $(this).attr('id') == 'sub-form-detail') {
        $(this).addClass('modal__form_hidden');
        $('.windows8').addClass('windows8_visible');
    }

    if( $(this).attr('id') == 'sub-form') {
        $(this).parents('.order-section__wrapper').addClass('order-section__wrapper_hidden');
        $(this).parents('.order-section').find('.windows8').addClass('windows8_visible');
    }

    if( $(this).attr('id') == 'free-training') {
        $(this).addClass('modal__form_hidden');
        $('.windows8').addClass('windows8_visible');
    }

}

$(document).on('af_complete', function(event, response) {
    var form = response.form
    $('.windows8').removeClass('windows8_visible');
    if( form.attr('id') == 'call-back-form' ) {
        if (response.success) {
            
            $(form).parents('.modal__form-container').append("<div class='modal__succes-text'>Данные успешно отправлены, скоро мы Вам перезвоним</div>")
            setTimeout(function(){
                $(form).removeClass('modal__form_hidden');
                $('.modal__succes-text').remove();
                $('.modal__close-button').trigger('click');
            },3000)
        }
        response.message='';
    }
    if( form.attr('id') == 'free-training' ) {
        if (response.success) {
            
            $(form).parents('.modal__form-container').append("<div class='modal__succes-text'>Спасибо, ваша заявка принята</div>")
            setTimeout(function(){
                $(form).removeClass('modal__form_hidden');
                $('.modal__succes-text').remove();
                $('.modal__close-button').trigger('click');
            },3000)
        }
        response.message='';
    }
    if ( form.attr('id') == 'sub-form') {
        $(form).parents('.order-section__wrapper').removeClass('order-section__wrapper_hidden');
    }

    if ( form.attr('id') == 'sub-form-detail') {        
        $(form).removeClass('modal__form_hidden');
    }
    
    console.log(response);
});